import React from "react";

const Test = () => {
  return (
    <div>
      <div className="h-20 flex shadow-lg border-2 rounded-3xl bg-[#F5F5F5] pt-4 pb-4">
        API key : <input type="text"></input>
      </div>
    </div>
  );
};
export default Test;
