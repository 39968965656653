import React from "react";
import Info from "../Assets/info.jpg";

function UseBasicInfo(props) {
  return (
    <div className="mainbody">
      <img src={Info} alt="Info" />
    </div>
  );
}

export default UseBasicInfo;
