import React from "react";
import moyaho from "../Assets/moyaho.png";

function Main(props) {
  return (
    <div className="mainbody bg-[url('./Assets/moyaho.png')">
      <img src={moyaho} className="w-full h-screen"></img>
    </div>
  );
}

export default Main;
